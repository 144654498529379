import * as React from 'react';

import SearchSymbolSvg from '../../../assets/SearchSymbol.svg';
import style from './SearchSymbol.scss';

export const SearchSymbol: React.FC = () => {
  return (
    <div className={style.root}>
      <SearchSymbolSvg />
    </div>
  );
};
